import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import { Spin, Modal,Tooltip } from 'antd'
import { setBanner } from '../../redux/actions/ComponentAction'
import {
  setSpace,
  deleteSpace,
  selectSpace,
  updateSpace,
} from '../../redux/actions/SpaceAction'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import deleteImage from './../../img/delete.png'
import trashIcon from './../../img/trash_icon.png'
import i_symbol from './../../img/i_symbol.png'
import groupOfGuest from './../../img/group_of_guest.png'
import Placeholder from './../../img/Placeholder.png'
import threeDot from './../../img/dot.png'
import './../../style/spaceList.css'
import { useNavigate } from 'react-router-dom'
import { baseURL } from '../../redux/requests'
import { Progress } from 'antd'

export default function SpaceList() {
  const banner = useSelector((state) => state.component.banner)
  const spaces = useSelector((state) => state.space.spaces)
  const property = useSelector((state) => state.information.property)
  const information = useSelector((state) => state.information) // maybe Host info
  // console.log(`information`)
  // console.log(information)
  const [draftSpace, setDraftSpace] = useState({})
  const [beignReviewedSpace, setBeignReviewedSpace] = useState({})
  const [publishedSpace, setPublishedSpace] = useState({})

  const [spaceInformationDone, setSpaceInformationDone] = useState({})
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(true)
  const [showPageLoader, setShowPageLoader] = useState(false)

  const todaysDate = moment().format('YYYY-MM-DD')
  // const present_time = moment().format('hh:mm:ss')
  let present_time = moment()
  const remainder = 30 - (present_time.minute() % 30)
  present_time = moment().add(remainder, 'minutes').format('hh:mm:ss')
  const present_time_plus_added = moment()
    .add(remainder, 'minutes')
    .add(2, 'hours')
    .format('hh:mm:ss')

  useEffect(() => {
    if (loading) {
      setShowPageLoader(true)
      // let data = {}
      // spaces && Object.keys(spaces).map( (key,index)=>{
      //     getFinishedPercentage(key).then( (value)=>{ data[key] = value })
      // })
      // setSpaceInformationDone(data);
      dispatch(
        setBanner({
          header: 'Your Space: Listings',
          discription: 'Complete your first listing and you’ll be a host soon!',
          button: {
            visible: true,
            link: '/space/add/new',
            name: 'Add a space',
          },
          progress_bar: {
            visible: false,
            progress: 0,
          },
          pages: {
            visible: false,
            total_page: 0,
            page_number: 0,
          },
          callBack: () => {
            // setLoading(false);
            dispatch(
              setSpace({
                callBack: (response) => {
                  // console.log(spaces)
                  setShowPageLoader(false)
                  let draft = {},
                    beignReviewed = {},
                    published = {}

                  Object.keys(response.data).map((space) => {
                    if (response.data[space].approval.approval == 'Draft') {
                      draft[space] = response.data[space]
                    }
                    if (
                      response.data[space].approval.approval == 'Being Reviewed'
                    ) {
                      beignReviewed[space] = response.data[space]
                    }
                    if (
                      response.data[space].approval.approval ==
                      'Published Listings'
                    ) {
                      published[space] = response.data[space]
                    }
                  })
                  setDraftSpace(draft)
                  setBeignReviewedSpace(beignReviewed)
                  setPublishedSpace(published)

                  setLoading(false)
                },
              }),
            )
          },
        }),
      )
    }
  }, [loading])

  let setUpSpaceData = (data) => {
    let draft = {},
      beignReviewed = {},
      published = {}

    Object.keys(data).map((space) => {
      if (data[space].approval.approval == 'Draft') {
        draft[space] = data[space]
      }
      if (data[space].approval.approval == 'Being Reviewed') {
        beignReviewed[space] = data[space]
      }
      if (data[space].approval.approval == 'Published Listings') {
        published[space] = data[space]
      }
    })
    setDraftSpace(draft)
    setBeignReviewedSpace(beignReviewed)
    setPublishedSpace(published)
  }

  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [selectedSpace, setSelectedSpace] = useState(null)

  let closeDeleteConfirmModal = async () => {
    document.body.style.overflow = 'visible'
    await setSelectedSpace(null)
    setShowDeleteModal(false)
  }

  let showDeleteConfirmModal = async (id) => {
    window.scrollTo(0, 0)
    document.body.style.overflow = 'hidden'
    await setSelectedSpace(id)
    setShowDeleteModal(true)
  }

  let handleDelete = () => {
    dispatch(
      deleteSpace({
        id: selectedSpace,
        callBack: (response) => {
          dispatch(
            setSpace({
              callBack: (response) => {},
            }),
          )
          document.body.style.overflow = 'visible'
          setUpSpaceData(response.data)
          setShowDeleteModal(false)
          // dispatch(setSpace({ callBack: (response) => {
          // }}))
        },
      }),
    )
  }

  let getSpaceInformation = async (id) => {
    let page_list = {
      setup: { link: `/space/add/${id}`, done: 0 },
      about: { link: `/space/add/${id}/about`, done: 16.666 },
      photos: { link: `/space/add/${id}/photos`, done: 16.666 * 2 },
      activities_and_pricing: {
        link: `/space/add/${id}/activities`,
        done: 16.666 * 4,
      },
      opt_out_activities: { link: `/space/add/${id}/optout`, done: 16.666 * 5 },
      amenities: { link: `/space/add/${id}/amenities`, done: 16.666 * 6 },
    }
    let page_empty = []
    await dispatch(
      selectSpace({
        id: id,
        callBack: (response) => {
          // console.log(response.data.setup);
          if (Object.keys(response.data.activities_and_pricing).length != 0) {
            for (let key in response.data.activities_and_pricing) {
              if (
                response.data.activities_and_pricing[key].opt_out_activities
                  .length == 0
              ) {
                page_empty.push('opt_out_activities')
              }
            }
          }

          if (
            spaces[id].setup.place_category == null ||
            spaces[id].setup.email == null ||
            spaces[id].setup.phone == null ||
            spaces[id].setup.number_of_guest == null ||
            spaces[id].setup.age == null
          ) {
            page_empty.push('setup')
          }

          if (
            response.data.about.description == null ||
            response.data.about.dimention == null
          ) {
            page_empty.push('about')
          }

          for (let key in response.data) {
            let typeOfdata = typeof response.data[key]
            if (typeOfdata == 'object') {
              if (Object.keys(response.data[key]).length == 0) {
                if (key != 'price') page_empty.push(key)
              }
            } else {
              if (response.data[key].length == 0) {
                if (key != 'price') page_empty.push(key)
              }
            }
          }
        },
      }),
    )
    return page_list[page_empty[0]]
  }

  let getFinishedPercentage = (id) => {
    let page_list = {
      setup: `/space/add/${id}`,
      about: `/space/add/${id}/about`,
      photos: `/space/add/${id}/photos`,
      activities_and_pricing: `/space/add/${id}/activities`,
      opt_out_activities: `/space/add/${id}/optout`,
      amenities: `/space/add/${id}/amenities`,
      covid_19_policy: '/space/policy-and-ruls/covid-19-policy',
      basic: '/space/basic/information',
      policies_and_rules: '/space/policy-and-ruls',
      house_ruls: '/space/policy-and-ruls/house-rules',
    }
    let page_empty = []
    let page_list_group = {
      "basic":[],
      "listings":[],
      "policy_and_rules":[]
    }

    if (spaces[id] == undefined) {
      return {
        link: '',
        done: `0%`,
      }
    }

    if (
      spaces[id].setup.place_category == null ||
      spaces[id].setup.email == null ||
      spaces[id].setup.phone == null ||
      spaces[id].setup.number_of_guest == null ||
      spaces[id].setup.age == null
    ) {
      page_empty.push('setup')
      page_list_group.listings.push({name:'setup',link:page_list['setup']})
    }

    // if (Object.keys(spaces[id].activities_and_pricing).length != 0) {
    //   for (let key in spaces[id].activities_and_pricing) {
    //     if (
    //       spaces[id].activities_and_pricing[key].opt_out_activities.length == 0
    //     ) {
    //       page_empty.push('opt_out_activities')
    //     }
    //   }
    // }

    if (
      spaces[id].about.description == null || spaces[id].about.description.length < 1 ||
      spaces[id].about.dimention == null ||
      spaces[id].about.title == null || spaces[id].about.title.length < 1
    ) {
      page_empty.push('about')
      page_list_group.listings.push({name:'about',link: !page_empty.includes('setup') ? page_list['about']  : page_list['setup']})
    }
    
    for (let key in spaces[id]) {
      let typeOfdata = typeof spaces[id][key]
      if (typeOfdata == 'object') {
        if (Object.keys(spaces[id][key]).length == 0) {
          if (key != 'price' && key != 'unique_id'){ 
            page_empty.push(key)
            page_list_group.listings.push({name:key,link: !page_empty.includes('setup') ? !page_empty.includes('about') ? page_list[key] : page_list['about'] : page_list['setup']})
          }
        }
      } else {
        if (spaces[id][key].length == 0) {
          if (key != 'price' && key != 'name' && key != 'unique_id') {
            page_empty.push(key)
            page_list_group.listings.push({name:key,link:!page_empty.includes('setup') ? !page_empty.includes('about') ? page_list[key] : page_list['about'] : page_list['setup']})
          }
        }
      }
    }

    if (property != undefined) {
      if (
        property.basic_information.latitude == null ||
        property.basic_information.longitud == null
      ) {
        page_empty.push('basic')
        page_list_group.basic.push({name:'basic',link:page_list['basic']})
      }

      // if (
      //   property.policies_and_rules.basic_policy.booking_window_rules.length <
      //     0 ||
      //   property.policies_and_rules.basic_policy.buffer_time_rules.length < 0
      // ) {
      //   page_empty.push('policies_and_rules')
      // }
      if (!property.policies_and_rules?.house_rules?.rules) {
        page_empty.push('house_ruls')
        page_list_group.policy_and_rules.push({name:'house_ruls',link:page_list['house_ruls']})
      }
      

      // console.log(property.policies_and_rules.covid_19_policy.cleaning_process.length == 0 );
      // if (property.policies_and_rules.covid_19_policy == undefined) {
      //   page_empty.push('covid_19_policy')
      // }

      // if (property.policies_and_rules.covid_19_policy) {
      //   if (
      //     property.policies_and_rules.covid_19_policy.cleaning_process.length ==
      //     0
      //   ) {
      //     page_empty.push('covid_19_policy')
      //   }
      // }
    }
    let data = {
      page_list_group:page_list_group,
      page_name: page_empty,
      link: page_list[page_empty[0]],
      done: `${Math.floor((12 - page_empty.length) * 8.333333333333333)}%`,
    }
    
    if (data.done == '99%') {
      data.done = '100%'
    }

    return data
  }

  let handleResume = async (id, event, entireSpace, actionString) => {
    // console.log(id)
    let empty_page = await getSpaceInformation(id)
    let finishedPercentage = getFinishedPercentage(id)

    function detectSpecialKeys(url) {
      if (event.ctrlKey || event.metaKey) {
        window.open(url, '_blank')
      } else {
        navigate(url)
      }
    }
    if (finishedPercentage.done !== '100%') {
      // navigate(finishedPercentage.link) // ! not using because of special keys not working (e.g. not opening new tab on command + click)
      // This below check is done, detectSpecialKeys() is called but it is not returing the correct link to navigate (for some spaces, not all spaces, as the time a "Draft" space with 66% complete, not getting any link from detectSepcialKeys())
      let u = finishedPercentage.link
      if (!u) u = `/space/add/${id}/setup-information`
      detectSpecialKeys(u)
    } else {
      if (!entireSpace) alert('Something went wrong')
      if (actionString === 'View Listing') {
        detectSpecialKeys(
          `/renter/place/${entireSpace.unique_id}/${present_time}/${present_time_plus_added}/${todaysDate}/${todaysDate}`,
          '_blank',
        )
      } else if (actionString === 'Edit') {
        detectSpecialKeys(`/space/add/${id}`, '_blank')
      }
    }
  }

  const [showSubmitForReviewButtonLoader, setShowSubmitForReviewButtonLoader] =
    useState(false)
  let submitForReview = (id) => {
    // console.log(information)
    if (!information.phone_verified || !information.verified) {
      Modal.error({
        title: "Can't submit for review",
        content: (
          <div>
            <p style={{}}>
              You need to verify both your email and your phone number to submit
              your listing for admin review.
            </p>
          </div>
        ),
        onOk() {},
      })
      return
    }
    setShowSubmitForReviewButtonLoader(true)
    dispatch(
      updateSpace({
        id: id,
        approval: true,
        callBack: (response) => {
          setShowSubmitForReviewButtonLoader(false)
          setUpSpaceData(response.data.spaces)
          // console.log(response.data.spaces);
        },
      }),
    )
  }
  return (
    <div
      className="hide-scrollbar"
      style={{ height: 'calc(100vh - 331px)', overflow: 'auto', padding: 50 }}
    >
      {showPageLoader && (
        <div>
          <Spin size="large" style={{ width: '100%', margin: '40px auto' }} />
        </div>
      )}
      {/* <p>{JSON.stringify(information.verified)}</p> */}
      {/* <p>{JSON.stringify(information.phone_verified)}</p> */}
      {(!information.verified || !information.phone_verified) && (
        <div
          style={{
            display: 'flex',
            background: '#e8e8fb',
            padding: 15,
            alignItems: 'center',
            border: '1.5px solid red',
            borderRadius: 5,
            marginBottom: 20,
            maxWidth: '70%',
          }}
        >
          <div style={{ marginRight: 15 }}>
            <InfoCircleOutlined style={{}} className="info_icon_antd" />
          </div>
          <p style={{ marginBottom: 0, fontSize: 16 }}>
            You need to{' '}
            <span style={{ fontSize: 16, fontWeight: 'bold' }}>
              verify both your email and your phone number
            </span>{' '}
            to submit your listing for admin review.{' '}
          </p>
        </div>
      )}

      {Object.keys(spaces).length > 0 ? (
        <>
          {Object.keys(publishedSpace).length > 0 ? (
            <h4 className="approval-status-name" style={{ marginTop: 20 }}>
              Published Listings
            </h4>
          ) : (
            ''
          )}

          {publishedSpace &&
            Object.keys(publishedSpace).map((key, index) => {
              let done = getFinishedPercentage(key)
              let space_image = spaces[key] && spaces[key]['photos'][0]
              return (
                <div className="space-card">
                  <div className="space-image">
                    <img
                      style={{
                        width: '132px',
                        height: '97px',
                        objectFit: 'contain',
                      }}
                      src={
                        space_image != undefined
                          ? `${baseURL.replace('/api', '')}${space_image.image}`
                          : Placeholder
                      }
                      alt=""
                    />
                  </div>
                  <div className="space-content">
                    <h1>{spaces[key] && spaces[key]['about']['title']}</h1>
                    {/* <div className="progress pace-content-progress-bar" style={{
                                                height: '4px',
                                                borderRadius: '0px',
                                                border: 'black'
                                            }}>
                                                <div className="progress-bar" role="progressbar" style={{
                                                    width:`${done.done}`,
                                                    background:'black'
                                                }} aria-valuenow={`5`} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div> */}
                    {done.done == '100%' ? (
                      <div className="space-card-basic-information">
                        {/* <div className='space-card-basic-information-guest-number'> from ${ spaces[key]['price'][Object.keys(spaces[key]['price'])[0]]['price']  }{ spaces[key]['price'][Object.keys(spaces[key]['price'])[0]]['pricing_condition'] == 1 ?  '/hour' : '/day' }</div> */}
                        <div className="space-card-basic-information-guest-number">
                          {' '}
                          {spaces[key] &&
                            spaces[key]['setup']['place_category'].replaceAll(
                              '_',
                              ' ',
                            )}
                        </div>
                        {/* <div className='space-card-basic-information-guest-number'> <img src={groupOfGuest} alt="" style={{textTransform:"capitalize"}}/> {spaces[key]['setup']['number_of_guest'] && spaces[key]['setup']['number_of_guest'].replaceAll('_'," ")}</div> */}
                        {/* <div className='space-card-basic-information-address'>Manhattan, New York</div> */}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  {/* <p style={{position:'absolute',right:'21rem',top:'4.5rem'}} >{done.done}</p> */}
                  <div className="space-buttons">
                    <div
                      className="space-resume-button"
                      // style={{ border: '1px solid red' }}
                      onClick={(event) => {
                        handleResume(
                          key,
                          event,
                          spaces[key],
                          done.done !== '100%' ? 'Resume' : 'View Listing',
                        )
                      }}
                    >
                      {done.done !== '100%' ? 'Resume' : 'View Listing'}
                    </div>
                    <button
                      onClick={() => {
                        document
                          .getElementById(`space-manue-${key}`)
                          .classList.toggle('hide-elements')
                      }}
                      onBlur={() => {
                        document
                          .getElementById(`space-manue-${key}`)
                          .classList.toggle('hide-elements')
                      }}
                      // onFocus={showDeleteConfirmModal}
                      // onBlur={closeDeleteConfirmModal}
                      className="space-show-space-cart-manu-button"
                    >
                      <img src={threeDot} alt="" srcSet="" />
                      <div
                        className="space-show-space-cart-manu hide-elements"
                        id={`space-manue-${key}`}
                      >
                        <ul>
                          <li
                            onClick={(event) => {
                              handleResume(key, event, spaces[key], `Edit`)
                            }}
                          >
                            Edit
                          </li>
                          <li
                            onClick={() => {
                              showDeleteConfirmModal(key)
                            }}
                          >
                            Delete
                          </li>
                        </ul>
                      </div>
                    </button>
                  </div>
                </div>
              )
            })}
          {Object.keys(beignReviewedSpace).length > 0 ? (
            <h4 className="approval-status-name">Being Reviewed</h4>
          ) : (
            ''
          )}

          {beignReviewedSpace &&
            Object.keys(beignReviewedSpace).map((key, index) => {
              let done = getFinishedPercentage(key)
              let space_image = spaces[key] && spaces[key]['photos'][0]
              return (
                <div
                  className="space-card"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div className="space-image">
                      <img
                        style={{
                          width: '132px',
                          height: '97px',
                          objectFit: 'contain',
                        }}
                        src={
                          space_image != undefined
                            ? `${baseURL.replace('/api', '')}${
                                space_image.image
                              }`
                            : Placeholder
                        }
                        alt=""
                      />
                    </div>
                    <div className="space-content">
                      <h1>{spaces[key] && spaces[key]['about']['title']}</h1>

                      <div
                        className="progress pace-content-progress-bar"
                        style={{
                          height: '4px',
                          borderRadius: '0px',
                          border: 'black',
                        }}
                      >
                        <div
                          className="progress-bar"
                          role="progressbar"
                          style={{
                            width: `${done.done}`,
                            background: 'black',
                          }}
                          aria-valuenow={`5`}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                      {done.done == '100%' ? (
                        <div className="space-card-basic-information">
                          {/* <div className='space-card-basic-information-guest-number'> from ${ spaces[key]['price'][Object.keys(spaces[key]['price'])[0]]['price']  }{ spaces[key]['price'][Object.keys(spaces[key]['price'])[0]]['pricing_condition'] == 1 ?  '/hour' : '/day' }</div> */}
                          <div
                            className="space-card-basic-information-guest-number"
                            style={{ textTransform: 'capitalize' }}
                          >
                            {' '}
                            {spaces[key]['setup']['place_category'].replaceAll(
                              '_',
                              ' ',
                            )}{' '}
                          </div>
                          <div className="space-card-basic-information-guest-number">
                            {' '}
                            <img src={groupOfGuest} alt="" />{' '}
                            {spaces[key]['setup']['number_of_guest']}
                          </div>
                          {/* <div className='space-card-basic-information-address'>Manhattan, New York</div> */}
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <p
                      style={{
                        position: 'absolute',
                        right: '21rem',
                        top: '4.5rem',
                      }}
                    >
                      {done.done}
                    </p>
                    <div
                      className="space-buttons"
                      // style={{ marginTop: '-180px' }}
                    >
                      <div
                        className="space-resume-button"
                        onClick={(event) => {
                          handleResume(
                            key,
                            event,
                            spaces[key],
                            done.done !== '100%' ? 'Resume' : 'Edit',
                          )
                        }}
                      >
                        {done.done !== '100%' ? 'Resume' : 'Edit'}
                      </div>
                      {/* <button 
                                                onClick={() => {showDeleteConfirmModal(key)}} 
                                                // onFocus={showDeleteConfirmModal} 
                                                // onBlur={closeDeleteConfirmModal} 
                                                className="space-delete-button"
                                            ><img src={deleteImage} alt="" srcSet="" /></button> */}
                    </div>
                  </div>
                  <div>
                    <div className="space-card-message">
                      <img src={i_symbol} alt="" />
                      <div>
                        <h4>Our team is reviewing your listing</h4>
                        <p>
                          We’ll notify you via email when your listings are
                          live, or if you need to make additional updates. If
                          you don’t hear from us within 5 business days, please
                          email us hello@Venuely.org
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          {Object.keys(draftSpace).length > 0 ? (
            <h4 className="approval-status-name">Draft</h4>
          ) : (
            <>
              {!showPageLoader && (
                <h4 className="approval-status-name">Draft</h4>
              )}
            </>
          )}
          {draftSpace &&
            Object.keys(draftSpace).map((key, index) => {
              let done = getFinishedPercentage(key)
              let space_image = spaces[key] && spaces[key]['photos'][0]
              return (
                <div className="space-card">
                  <div className="space-image">
                    <img
                      style={{
                        width: '132px',
                        height: '97px',
                        objectFit: 'contain',
                      }}
                      src={
                        space_image != undefined
                          ? `${baseURL.replace('/api', '')}${space_image.image}`
                          : Placeholder
                      }
                      alt=""
                    />
                  </div>
                  <div className="space-content">
                    <h1>{spaces[key] && spaces[key]['about']['title']} </h1>
                    <Tooltip placement="topLeft" title={done.page_name.join(', ').replaceAll('_',' ')}>
                      <Progress percent={done.done.replaceAll('%', '')} />
                    </Tooltip>
                    
                    {done.done == '100%' ? (
                      <div className="space-card-basic-information">
                        <div
                          className="space-card-basic-information-guest-number"
                          style={{ textTransform: 'capitalize' }}
                        >
                          {' '}
                          {spaces[key] &&
                            spaces[key]['setup']['place_category'].replaceAll(
                              '_',
                              ' ',
                            )}
                        </div>
                        <div className="space-card-basic-information-guest-number">
                          {' '}
                          <img src={groupOfGuest} alt="" />{' '}
                          {spaces[key] &&
                            spaces[key]['setup']['number_of_guest']}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    <ul style={{paddingLeft:"0px"}}>
                      {
                        Object.keys(done.page_list_group).map((key)=>{
                          if(done.page_list_group[key].length > 0){
                            return <li style={{float:'left',marginLeft:'10px'}}> 
                              <details>
                              <summary>{key.replaceAll('_',' ')}</summary>
                              <p>
                                <ul style={{paddingLeft:"10px"}}>
                                  {done.page_list_group[key].map((obj)=>{
                                    return <li> <a onClick={()=>navigate(obj.link)}> {obj.name.replaceAll('_'," ")} </a></li>
                                  })}
                                </ul>
                              </p>
                              </details>
                              
                            </li>

                          }
                        })
                      }
                    </ul>
                  </div>
                  
                  <div className="space-buttons">
                    <div className="space-card-button-group">
                      {done.done == '100%' ? (
                        <div
                          style={{ position: 'relative' }}
                          className="space-submit-for-review-button"
                          onClick={() => {
                            submitForReview(key)
                          }}
                        >
                          {showSubmitForReviewButtonLoader ? (
                            <div
                              style={{
                                height: '33px',
                                width: '33px',
                                top: '5px',
                              }}
                              className={`loader`}
                            ></div>
                          ) : (
                            'Submit for Review'
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                      <Tooltip placement="topLeft" title={done.page_name.join(', ').replaceAll('_',' ')}>
                        <div
                          className="space-resume-button"
                          onClick={(event) => {
                            handleResume(
                              key,
                              event,
                              spaces[key],
                              done.done !== '100%' ? 'Resume' : 'Edit',
                            )
                          }}
                        >
                          {done.done !== '100%' ? 'Resume' : 'Edit'}
                        </div>
                      </Tooltip>
                    </div>
                    <button
                      onClick={() => {
                        showDeleteConfirmModal(key)
                      }}
                      // onFocus={showDeleteConfirmModal}
                      // onBlur={closeDeleteConfirmModal}
                      className="space-delete-button"
                    >
                      <img src={deleteImage} alt="" srcSet="" />
                    </button>
                  </div>
                  
                </div>
              )
            })}
          {Object.keys(draftSpace).length > 0 ? (
            ''
          ) : (
            <>
              {!showPageLoader && (
                <div
                  className="data-showing-box"
                  style={{ marginTop: '40px', marginLeft: '44px' }}
                >
                  <p>Currently you don’t have any listings</p>
                </div>
              )}
            </>
          )}
          {showDeleteModal ? (
            <div className="delete-box-wrapper">
              <div className="delete-box-card">
                <div className="delete-box-card-header">
                  <p> </p>
                  <button
                    type="button"
                    className="delete-box-card-close-button"
                    onClick={closeDeleteConfirmModal}
                  >
                    {' '}
                    x{' '}
                  </button>
                </div>
                <div className="delete-box-card-body">
                  <img src={trashIcon} alt="" srcSet="" />
                  <h4>Are You Sure?</h4>
                  <p>
                    Do you really want to delete this listing ? <br /> This
                    process cannot be undone.
                  </p>
                </div>
                <div className="delete-box-card-footer">
                  <button type="button" onClick={closeDeleteConfirmModal}>
                    {' '}
                    cancel{' '}
                  </button>
                  <button type="button" onClick={handleDelete}>
                    {' '}
                    delete{' '}
                  </button>
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        ''
      )}
    </div>
  )
}
