import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import uparrow from './icons/uparrow.svg'
import filter from './icons/filter.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import '../style/SearchResult.css'
import '../style/antd.css'
import Morefilters from '../components/popups/Morefilters'
import ActivityModal from '../components/popups/ActivityModal'
import PriceMenu from '../components/menus/PriceMenu'
import OccupencyMenu from '../components/menus/OccupencyMenu'
import { useDispatch, useSelector } from 'react-redux'
import useInterval from '@use-it/interval'
import { refresh } from '../redux/actions/AuthAction'
import Topbar from '../components/Topbar/Topbar'
import moment from 'moment'
import groupIcon from '../img/grp.svg'
import scaleIcon from '../img/scale.svg'
import { Button, Carousel, Skeleton, Spin } from 'antd'
import { CloseOutlined, CloseCircleOutlined } from '@ant-design/icons'
import NoSpaceDescription from '../components/NoSpaceDescription'
import leftArrowImg from '../pages/icons/leftPurpleArrow.png'
import rightArrowImg from '../pages/icons/rightPurpleArrow.png'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import ReactGA from 'react-ga4'
import { AnalyticsEventTracker } from '../components/utils/AnalyticsEventTracker'

const getSessionToken = () =>
  new window.google.maps.places.AutocompleteSessionToken()

const SearchResult = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [center, setCenter] = useState({ lat: 40.7127753, lng: -74.0059728 })
  const [markerClicked, setMarkerClicked] = useState(false)
  const [propertyId, setPropertyId] = useState(0)
  const [insideMapPopup, setInsideMapPopup] = useState(false)
  const [activityItemsArray, setactivityItemsArray] = useState([])
  const [timeVal, setTimeVal] = useState(false)
  const [activityModal, setActivityModal] = useState(false)
  const [spaceList, setSpaceList] = useState(null)
  const [locSelected, setLocSelected] = useState(false)
  const [locMenu, setLocMenu] = useState(false)
  const [activityList, setactivityList] = useState(false)
  const [locItemSelected, setLocItemSelected] = useState(false)
  const [locItemVal, setLocItemVal] = useState('')
  const [startDatemenu, setstartDatemenu] = useState(false)
  const [endDatemenu, setendDatemenu] = useState(false)
  const currentTimeHour =
    moment().minutes() > 30
      ? moment().add(30, 'minutes').format('HH')
      : moment().format('HH')
  const currentTimeMin =
    moment().minutes() > 0 ? (moment().minutes() < 15 ? '15' : '30') : '00'
  const currentTimeStart = currentTimeHour + ':' + currentTimeMin + ':' + '00'
  const currentTimeEnd =
    +currentTimeHour + 3 + ':' + currentTimeMin + ':' + '00'

  const url = new URL(window.location.href)
  const startTimeURLValue = url.searchParams.get('startTime')
  const endTimeURLValue = url.searchParams.get('endTime')
  const [startTime, setStartTime] = useState(startTimeURLValue || '')
  const [endTime, setEndTime] = useState(endTimeURLValue || '')
  const [flexibleTime, setFlexibleTime] = useState(false)
  const [calendarPopup, setCalendarPopup] = useState('')
  const [pricePopup, setPricePopup] = useState(false)
  const [occupencyPopup, setOccupencyPopup] = useState(false)
  const [morefiltersPopup, setMorefiltersPopup] = useState(false)
  const [doneSearch, setdoneSearch] = useState(false)
  const [address, setAddress] = useState('')
  const [allActivities, setallActivities] = useState([])
  const [event, setEvent] = useState(false)
  const valueDate = moment().format('YYYY-MM-DD')
  const [dateVal, setDateVal] = useState(valueDate)
  const [dateVal2, setDateVal2] = useState(valueDate)
  const authUser = useSelector((state) => state.auth.user)
  const [typeOfWorship, setTypeOfWorship] = useState([])
  const [placeCategory, setPlaceCatagory] = useState([])
  const [houseRules, setHouseRules] = useState([])
  const [amenity, setAmenity] = useState([])
  const baseURL = process.env.REACT_APP_BASE_URL
  const imgURL = baseURL.split('/api')[0]
  const isauthenticated = useSelector((state) => state.auth.authenticated)
  const [sessionToken, setSessionToken] = useState(getSessionToken())
  const mapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  const [loading, setLoading] = useState(false)
  const [propertyIdx, setPropertyIdx] = useState(null)
  const [imgNumber, setImgNumber] = useState(0)
  const [occupencyVal, setOccupencyVal] = useState('')
  const [exactOccupencyValue, setExactOccupency] = useState('')
  const [priceVal, setPriceVal] = useState('')
  const [exactPriceVal, setExactPriceVal] = useState('')
  const [selectedActivites, setSelectedActivites] = useState([])

  // useEffect(() => {
  //   ReactGA.send({ type: "Search Page View", path: window.location.pathname + window.location.search })
  // }, [])

  useEffect(() => {
    if (isauthenticated) {
      if (authUser.type == 'Host') {
        navigate('/pending-request')
      }

      if (authUser.type == 'Guest') {
        // location('/')
      }
    }
  }, [2])

  const Marker = ({ val, pricing_condition_id }) => (
    <div style={{ height: 32, width: 32 }}>
      <label
        onClick={() => {
          setInsideMapPopup(true)
          setPropertyId(val.id)
          setMarkerClicked(true)
        }}
        style={{
          backgroundColor:
            propertyId === val.id && markerClicked ? '#6568E6' : '#fff',
          color: propertyId === val.id && markerClicked ? '#fff' : '000',
          padding: 5,
          borderRadius: 5,
          outline: '#acacac 0px solid',
          boxShadow: '#00000047 0px 0px 10px',
          fontSize: 12,
          fontWeight: 'bold',
        }}
      >
        ${val.price}
      </label>
      <div
        className={
          propertyId === val.id && insideMapPopup
            ? 'map-popup'
            : 'map-popup-none'
        }
        style={{ position: 'absolute', top: '-32%' }}
      >
        <div style={{ display: 'grid', cursor: 'pointer' }}>
          <img
            src={
              val?.property.image[0].image !== null
                ? `${imgURL}${val?.property.image[0].image}`
                : 'dummyRooms.png'
            }
            alt=""
            style={{ width: 200, height: 150 }}
          />
          <div
            style={{
              position: 'absolute',
              marginTop: 8,
              marginLeft: 12,
              backgroundColor: '#fff',
              padding: '0px 5px',
              borderRadius: 3,
              fontSize: 14,
              fontWeight: 600,
            }}
          >
            ${val?.price} {pricing_condition_id == 2 ? `/day` : `/hr`}
          </div>
          <div style={{ margin: 8 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img src={groupIcon} alt="" />
              <label
                style={{
                  fontFamily: 'Inter',
                  fontWeight: 600,
                  fontSize: 14,
                  margin: '8px 5px',
                }}
              >
                {val?.property?.description?.capacity}
              </label>
            </div>
            <label className="room-label" style={{ marginTop: 8 }}>
              {val?.property.name}
            </label>
            <h4 style={{ width: 200, fontWeight: 600, marginTop: 8 }}>
              {val?.property.address}
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      const classList = e.target.classList
      if (
        classList.contains('search-page') ||
        classList.contains('search-page-parent') ||
        classList.contains('no-space-div') ||
        classList.contains('no-space-img') ||
        classList.contains('top__header') ||
        e.target.id === 'root'
      ) {
        setPricePopup(false)
        setOccupencyPopup(false)
        // setCalendarPopup(false)
      }
    })
  }, [])

  const TIME_OUT = 360000
  useInterval(() => {
    dispatch(
      refresh({
        callBack: (response) => {
          // console.log(response)
        },
      }),
    )
  }, TIME_OUT)

  const [propertyImageLoaded, setPropertyImageLoaded] = useState(false)

  const handleTagClose = (id) => {
    setSelectedActivites((prev) =>
      prev.filter((item, idx) => {
        // console.log(item.id)
        // console.log(id)
        return item.id !== id
      }),
    )
  }

  return (
    <>
      <Topbar
        doneSearch={doneSearch}
        setdoneSearch={setdoneSearch}
        flexibleTime={flexibleTime}
        setFlexibleTime={setFlexibleTime}
        address={address}
        setAddress={setAddress}
        dateVal={dateVal}
        dateVal2={dateVal2}
        occupencyVal={occupencyVal}
        amenity={amenity}
        typeOfWorship={typeOfWorship}
        placeCategory={placeCategory}
        houseRules={houseRules}
        exactOccupencyValue={exactOccupencyValue}
        exactPriceVal={exactPriceVal}
        priceVal={priceVal}
        setPriceVal={setPriceVal}
        locItemSelected={locItemSelected}
        activityList={activityList}
        setactivityList={setactivityList}
        setCalendarPopup={setCalendarPopup}
        locItemVal={locItemVal}
        setLocItemVal={setLocItemVal}
        setLocItemSelected={setLocItemSelected}
        timeVal={timeVal}
        calendarPopup={calendarPopup}
        setLocMenu={setLocMenu}
        setTimeVal={setTimeVal}
        startTime={startTime}
        endTime={endTime}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        startDatemenu={startDatemenu}
        setstartDatemenu={setstartDatemenu}
        endDatemenu={endDatemenu}
        setendDatemenu={setendDatemenu}
        setallActivities={setallActivities}
        activityModal={activityModal}
        activityItemsArray={activityItemsArray}
        setactivityItemsArray={setactivityItemsArray}
        setActivityModal={setActivityModal}
        setEvent={setEvent}
        setCenter={setCenter}
        setSpaceList={setSpaceList}
        setLoading={setLoading}
        setLocSelected={setLocSelected}
        setDateVal={setDateVal}
        setDateVal2={setDateVal2}
        selectedActivites={selectedActivites}
        setSelectedActivites={setSelectedActivites}
      />
      {
        <div
          className="search-page-parent"
          style={{
            width: '90%',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <div
            className="search-page"
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: 40,
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div
                className="bar"
                onClick={() => {
                  setOccupencyPopup(!occupencyPopup)
                  setPricePopup(false)
                }}
              >
                <h4>Occupancy</h4>
                <img
                  src={uparrow}
                  alt=""
                  style={{ transform: 'rotate(180deg)' }}
                />
              </div>
              <div
                className="bar"
                onClick={() => {
                  setPricePopup(!pricePopup)
                  setOccupencyPopup(false)
                }}
              >
                <h4>Price</h4>
                <img
                  src={uparrow}
                  alt=""
                  style={{ transform: 'rotate(180deg)' }}
                />
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {selectedActivites.map((el, idx) => {
                return (
                  <div
                    className="bar"
                    id={idx}
                    onClick={(e) => handleTagClose(el.id)}
                    // closable // ! no need to use closeable, its buggy
                    // onClose={(e) => handleTagClose(el.id)}
                  >
                    <h4>{el.title}</h4>
                    <div style={{ marginBottom: 20, marginRight: 5 }}>
                      <CloseCircleOutlined style={{ color: '#8a8787' }} />
                    </div>
                  </div>
                )
              })}
            </div>
            <div className="bar" onClick={() => setMorefiltersPopup(true)}>
              <h4>More filters</h4>
              <img
                src={filter}
                alt=""
                style={{ transform: 'rotate(180deg)' }}
              />
            </div>
          </div>
          <Morefilters
            amenity={amenity}
            setAmenity={setAmenity}
            houseRules={houseRules}
            setHouseRules={setHouseRules}
            setTypeOfWorship={setTypeOfWorship}
            setPlaceCatagory={setPlaceCatagory}
            isModalVisible={morefiltersPopup}
            setIsModalVisible={setMorefiltersPopup}
          />
          <OccupencyMenu
            exactValue={exactOccupencyValue}
            setExactValue={setExactOccupency}
            occupencyVal={occupencyVal}
            setOccupencyVal={setOccupencyVal}
            setOccupencyPopup={setOccupencyPopup}
            occupencyPopup={occupencyPopup}
          />
          <PriceMenu
            exactPriceVal={exactPriceVal}
            setExactPriceVal={setExactPriceVal}
            priceVal={priceVal}
            setPriceVal={setPriceVal}
            setPricePopup={setPricePopup}
            pricePopup={pricePopup}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              borderTop: '#ccc 0.5px solid',
              borderWidth: 1,
            }}
          >
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    marginTop: 5,
                    marginBottom: 60,
                  }}
                >
                  {locSelected ? (
                    spaceList?.data?.map((room, index) => {
                      return (
                        <div key={room.id} className="specific__property">
                          <div
                            style={{
                              width: 240,
                              margin: '10px 0',
                              height: 240,
                              overflow: 'hidden',
                            }}
                            onMouseEnter={() => setPropertyIdx(index)}
                            onMouseLeave={() => setPropertyIdx(null)}
                          >
                            <Carousel
                              effect="fade"
                              speed={300}
                              arrows={propertyIdx === index ? true : false}
                              nextArrow={<img src={rightArrowImg} />}
                              prevArrow={<img src={leftArrowImg} />}
                            >
                              {room.property.image.map((el, idx) => {
                                return (
                                  <div
                                    style={{
                                      width: 240,
                                      height: 200,
                                      overflow: 'hidden',
                                      objectFit: 'fill',
                                      borderRadius: 8,
                                    }}
                                    onClick={(ev) => {
                                      AnalyticsEventTracker(
                                        'Click',
                                        'Property click',
                                        'Property click',
                                      )
                                      if (ev.ctrlKey || ev.metaKey) {
                                        return window.open(
                                          `/renter/place/${room.unique_id}/${startTime}/${endTime}/${dateVal}/${dateVal2}`,
                                        )
                                      }
                                      navigate(
                                        `/renter/place/${room.unique_id}/${
                                          startTime ? startTime : '08:00:00'
                                        }/${
                                          endTime ? endTime : '10:00:00'
                                        }/${dateVal}/${dateVal2}`,
                                        {
                                          state: {
                                            startTime,
                                            endTime,
                                          },
                                        },
                                      )
                                    }}
                                  >
                                    <img
                                      id="property__img"
                                      className="property__img"
                                      src={`${imgURL}${el.image}`}
                                      alt=""
                                      style={{
                                        objectFit: 'cover',
                                        width: 240,
                                        height: 200,
                                        borderRadius: 8,
                                        display: propertyImageLoaded
                                          ? 'block'
                                          : 'none',
                                      }}
                                      onLoad={() =>
                                        setPropertyImageLoaded(true)
                                      }
                                    />
                                    <div
                                      style={{
                                        display: propertyImageLoaded
                                          ? 'none'
                                          : 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                      }}
                                    >
                                      <Skeleton.Image
                                        active={true}
                                        style={{
                                          width: 240,
                                          height: 200,
                                          textAlign: 'center',
                                          borderRadius: 8,
                                        }}
                                      />
                                    </div>
                                  </div>
                                )
                              })}
                            </Carousel>
                          </div>
                          {/* <div style={{ position: 'absolute' }}>
                            <div className='img__arrow-btns' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: 240 }}>
                              <img src={leftArrowImg} style={{ width: 50 }} alt="" onClick={onChange} />
                              <img src={rightArrowImg} style={{ width: 50 }} alt="" onClick={onChange} />
                            </div>
                          </div> */}
                          <div className="desc">
                            <div style={{ position: 'relative' }}>
                              <div
                                style={{
                                  position: 'absolute',
                                  marginLeft: -245,
                                  backgroundColor: '#fff',
                                  borderRadius: 4,
                                  width: 82,
                                  height: 22,
                                }}
                              >
                                <h5
                                  style={{
                                    fontSize: 14,
                                    fontWeight: '500',
                                    textAlign: 'center',
                                  }}
                                >
                                  ${room?.price}{' '}
                                  {room.pricing_condition.id == 2
                                    ? `/day`
                                    : `/hr`}
                                </h5>
                              </div>
                            </div>
                            <h4
                              style={{
                                width: 250,
                                fontWeight: 500,
                                fontSize: 13,
                                color: '#656369',
                              }}
                            >
                              {room?.entrance_address}
                            </h4>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                // alignItems: 'center',
                                alignContent: 'center',
                                marginRight: 10,
                                width: '100%',
                                // border: '1px solid red',
                              }}
                            >
                              <h4 style={styles.roomPropertyName}>
                                {room?.property.name}
                              </h4>
                              <div
                                style={
                                  {
                                    // alignSelf: 'end',
                                    // marginRight: 20,
                                    // marginTop: 10,
                                  }
                                }
                              >
                                <div style={styles.eachPropertyTypeTitle}>
                                  <span
                                    style={styles.eachPropertyTypeTitleText}
                                  >
                                    {room.Pricing_type?.title}
                                  </span>
                                </div>
                              </div>
                            </div>
                            {/* <h4
                              style={{
                                width: 500,
                                fontWeight: '500',
                                fontSize: 16,
                              }}
                            >
                              {room?.property.address}
                            </h4> */}
                            <div className="icon-infos">
                              <div className="icon-infos-together">
                                <img src={groupIcon} alt="" />
                                <label
                                  style={{
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    marginLeft: 8,
                                    color: '#000',
                                  }}
                                >
                                  {room?.property.description.capacity}
                                </label>
                              </div>
                              <div className="icon-infos-together">
                                <img src={scaleIcon} alt="" />
                                <label
                                  style={{
                                    fontFamily: 'Inter',
                                    fontWeight: 500,
                                    fontSize: 14,
                                    marginLeft: 8,
                                    color: '#000',
                                  }}
                                >
                                  {room?.property.description.dimention +
                                    ' sqft'}
                                </label>
                              </div>
                            </div>
                            <h4
                              className="room-label"
                              style={{ width: 'max-content' }}
                            >
                              Community room
                            </h4>
                            <div
                              style={{
                                display: 'grid',
                                gridTemplateColumns: 'repeat(3,1fr)',
                                columnGap: '-5px',
                                textAlign: 'center',
                              }}
                            >
                              {room.property.amenity
                                .filter((item, idx) => idx < 6)
                                .map((item, idx) => (
                                  <li
                                    key={idx}
                                    className="room-label"
                                    style={{
                                      textOverflow: 'hidden',
                                      textAlign: 'left',
                                    }}
                                  >
                                    {item}
                                  </li>
                                ))}
                            </div>
                          </div>
                        </div>
                      )
                    })
                  ) : (
                    <NoSpaceDescription
                      title="
                  We need every section of the search bar to be filled in
                  order to give you search results."
                      para="Venuely is only available in the state of New York, New Jersey, and Indiana. However, we are planning to expand to other areas soon!"
                    />
                  )}
                </div>
              </>
              {locSelected && spaceList?.data.length === 0 && (
                <NoSpaceDescription
                  title="No Result Found"
                  para="We apologize. We can’t find any listing matching your search.
              Try to search again! "
                />
              )}
            </div>
            <div style={{ height: '80vh', width: '35vw' }}>
              <GoogleMapReact
                bootstrapURLKeys={{
                  key: mapsApiKey,
                }}
                defaultCenter={{ lat: 40.7127753, lng: -74.0059728 }}
                center={center}
                defaultZoom={11}
                zoom={11}
                onClick={(ev) => {
                  setCenter({ lat: ev.lat, lng: ev.lng })
                  setInsideMapPopup(!insideMapPopup)
                }}
              >
                {spaceList?.data?.map((space, idx) => {
                  return (
                    <Marker
                      key={idx}
                      val={space}
                      lat={space.property.latitude}
                      lng={space.property.longitud}
                      pricing_condition_id={space.pricing_condition.id}
                    />
                  )
                })}
              </GoogleMapReact>
            </div>
            <div className="activity-modal">
              <ActivityModal
                allActivities={allActivities}
                setallActivities={setallActivities}
                activityModal={activityModal}
                setActivityModal={setActivityModal}
                event={event}
                setEvent={setEvent}
              />
            </div>
          </div>
        </div>
      }
      {loading && (
        <div style={{ position: 'relative' }}>
          <div
            style={{
              position: 'absolute',
              top: '-90vh',
              left: '50%',
              transform: 'translate(-50%, 0)',
            }}
          >
            <Spin size="large" />
          </div>
        </div>
      )}
    </>
  )
}

const styles = {
  eachPropertyTypeTitle: {
    padding: 5,
    borderRadius: 4,
    border: '1.25px solid #6568e6',
    width: 170,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eachPropertyTypeTitleText: {
    color: '#6568e6',
    fontSize: 11,
    // fontWeight: 'lighter',
  },
  roomPropertyName: {
    width: 318,
    fontWeight: 500,
    fontSize: 16,
  },
}

export default SearchResult
